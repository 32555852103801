const INIT = 'custInfo/INIT';
const SET_CUST_INFO = 'custInfo/SET_CUST_INFO';
const SET_CLEAR_PRNT_INFO = 'custInfo/SET_CLEAR_PRNT_INFO';
const SET_CLEAR_CUST_INFO = 'custInfo/SET_CLEAR_CUST_INFO';
const SET_TEMP_SAVE_STEP_CD_INFO = 'custInfo/SET_TEMP_SAVE_STEP_CD_INFO';
const SET_CUST_TEMP_SAVE_INFO = 'custInfo/SET_CUST_TEMP_SAVE__INFO';
const SET_CUST_MINR_INFO = 'custInfo/SET_CUST_MINR_INFO';

const initialState = {
  custNo: '',
  custNm: '',
  bdt: '',
  sex: '',
  postNo: '',
  addr: '',
  dtlAddr: '',
  hphnNo: '',
  minrYn: '0',
  prntAtrtNm: '',
  prntAtrtBdt: '',
  prntCatCd: '',
  tempSaveStepCd: '',
  isAddSend: false,
  isUpload: false,
  isPdf: false,
  perCorp: 'PER',
  updMktAgrYn: '',
  reWriteYn: '',
  pdfDownYn: '',
  singleYn: false,
  pdfPreYn: false,
  pdfPreCustNo: '',
  copyYn: false,
};

export default function custInfo(state = initialState, { type, payload }) {
  switch (type) {
    case INIT:
      return initialState;
    case SET_CUST_INFO:
      let { name, value } = payload;

      if(name === 'minrYn') value = value === '0' ? '1' : '0';
      
      return { ...state, [name]: value };
    case SET_CLEAR_PRNT_INFO:
      return { 
        ...state,
        hphnNo: '',
        prntAtrtNm: '',
        prntAtrtBdt: '',
        prntCatCd: '',
      };
    case SET_CLEAR_CUST_INFO:
      return { 
        ...state,
        custNm: '',
        bdt: '',
        sex: '',
        hphnNo: '',
      };
    case SET_TEMP_SAVE_STEP_CD_INFO:
      let { custNo, tempSaveStepCd } = payload;
      
      return { 
        ...state,
        custNo,
        tempSaveStepCd,
      };
    case SET_CUST_TEMP_SAVE_INFO:
      const { 
        CUST_NO,
        CUST_NM,
        BDT,
        SEX,
        HPHN_NO,
        MINR_YN,
        POST_NO,
        ADDR,
        DTL_ADDR,
        PRNT_ATRT_NM,
        PRNT_ATRT_BDT,
        PRNT_CAT_CD,
      } = payload;
    
      return { 
        ...state,
        custNo: CUST_NO,
        custNm: CUST_NM,
        bdt: BDT,
        sex: SEX,
        hphnNo: HPHN_NO,
        minrYn: MINR_YN,
        postNo: POST_NO,
        addr: ADDR,
        dtlAddr: DTL_ADDR,
        prntAtrtNm: PRNT_ATRT_NM,
        prntAtrtBdt: PRNT_ATRT_BDT,
        prntCatCd: PRNT_CAT_CD,
      };
    case SET_CUST_MINR_INFO:
      let { isTrue } = payload;

      return { ...state, minrYn: isTrue };
    case 'persist/PURGE':
      return initialState;
    
    default:
      return state;
  }
}

export function init() {
  return {
    type: INIT,
  };
}

export function setCustInfo(name, value) {
  return {
    type: SET_CUST_INFO,
    payload: { name, value },
  };
}

export function setClearPrntInfo() {
  return {
    type: SET_CLEAR_PRNT_INFO,
    payload: {},
  };
}

export function setClearCustInfo() {
  return {
    type: SET_CLEAR_CUST_INFO,
    payload: {},
  };
}

export function setTempSaveStepCdInfo(data) {
  return {
    type: SET_TEMP_SAVE_STEP_CD_INFO,
    payload: { 
      custNo: data.CUST_NO,
      tempSaveStepCd: data.TEMP_SAVE_STEP_CD,
     },
  };
}

export function setCustTempSaveInfo(data) {
  return {
    type: SET_CUST_TEMP_SAVE_INFO,
    payload: data,
  };
}

export function setCustMinrInfo(isTrue) {
  return {
    type: SET_CUST_MINR_INFO,
    payload: { isTrue },
  };
}